<template lang="pug">
  .phase-item.flex.flex-row.items-center.h-16
    .flex-initial.pr-4(v-if="canEdit")
      app-icon.handle.cursor-move(icon="dragHandle")

    .flex-1
      app-text-input.w-full(
        v-if="editing"
        :value="phaseItem.name"
        @input="name = $event"
        v-focus
        @change="renamePhase"
        v-click-outside="stopEditing"
        @keyup.enter="stopEditing")

      .flex.flex-col(v-else)
        span.cursor-text(@click="startEditing") {{ phaseItem.name }}

    .flex-initial.pl-8
      app-icon.cursor-pointer.mr-4(icon="edit", @click.native="startEditing")
      app-icon.cursor-pointer(icon="delete", colour="red", @click.native="$emit('delete')")
</template>

<script>
export default {
  props: {
    value: {
      type: Object,
      required: true
    },
    canEdit: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      editing: false,
      name: this.value.name
    };
  },
  computed: {
    phaseItem() {
      return this.value;
    }
  },
  methods: {
    startEditing() {
      this.editing = true;
    },
    stopEditing() {
      this.editing = false;
    },
    renamePhase() {
      this.$emit("input", this.name);
    }
  }
};
</script>
