/* global $D */

import { sync } from "vuex-router-sync";
import VueSanitize from "vue-sanitize";
import Vuelidate from "vuelidate";
import vuelidateErrorExtractor, { templates } from "vuelidate-error-extractor";
import Vue from "vue";
import KPop from "@ckienle/k-pop";
import VModal from "vue-js-modal/dist/ssr.nocss";
import MultiSelect from "vue-multiselect";
import draggable from "vuedraggable";
import ScrollTo from "vue-scrollto";
import ValidationMessages from "@/config/ValidationMessages";
import ValidationAttributes from "@/config/ValidationAttributes";
import registerLegacyComponents from "@/legacy/Legacy";
import registerGlobalComponents from "@/global";
import registerDirectives from "@/directives";
import router from "./route";
import store from "./store";
import { apolloProvider } from "./vue-apollo";
import vueFilterPrettyBytes from "vue-filter-pretty-bytes";

import UIKit from "manageplaces-ui-kit";
/**
 * GLOBAL STYLING
 *
 * Whilst we don't want any proper global styles,
 * there are a few styles that make sense such as
 * anchor tags. This will also setup styles from
 * tailwind
 */
import(/* webpackChunkName: "ui-kit" */ "@/assets/css/tailwind-init.css");
import(/* webpackChunkName: "ui-kit" */ "@/assets/css/globals.css");
// import "@/assets/css/globals.css";
// import(/* webpackChunkName: "ui-kit" */ "manageplaces-ui-kit/dist/UIKit.css");
import "@ckienle/k-pop/themes/clean.css";
import Root from "@/components/elements/Root.vue";
import AppLink from "@/components/elements/AppLink.vue";
import AppLoader from "@/components/containers/AppLoader.vue";

const vueOpts = {
  provide: apolloProvider.provide(),
  router,
  store
};

Vue.config.productionTip = false;

Vue.use(VueSanitize);
Vue.use(Vuelidate);
Vue.use(vuelidateErrorExtractor, {
  //   template: FormInputWrapper,
  messages: ValidationMessages,
  attributes: ValidationAttributes
});
Vue.use(UIKit, { store, vueOpts });
Vue.component("FormWrapper", templates.FormWrapper);
Vue.use(KPop);
Vue.use(VModal, { dynamic: true });
Vue.component("multiselect", MultiSelect);
Vue.component("draggable", draggable);

Vue.component("root-folder", Root);
Vue.component("app-link", AppLink);
Vue.component("app-loader", AppLoader);
Vue.use(ScrollTo, {
  container: "body > .container"
});
Vue.use(vueFilterPrettyBytes);

sync(store, router);

registerLegacyComponents();
registerGlobalComponents();
registerDirectives();

window.mainAppVm = new Vue({
  ...vueOpts,
  created() {
    if (!store.state.serverNotices) {
      return;
    }
    store.state.serverNotices.forEach(notice => {
      let actions = notice.actions || [];
      actions.forEach(action => {
        action.action = () => {
          if (action.href) {
            const method = (action.method || "get").toLowerCase();
            if (method === "get") {
              window.location.href = action.href;
            } else {
              const self = this;
              $D.fn.ajax[method]({
                url: action.href,
                success(resp) {
                  self.$flash.success(resp.message);
                },
                error: $D.fn.ajax.defaultErrorHandler
              });
            }
          }
        };
      });

      if (notice.type === "warning") {
        this.$notice.warn(notice.text, actions);
      } else {
        this.$notice.info(notice.text, actions);
      }
    });
  }
}).$mount("#mp-app");
