<script>
/* global accounting */
/* eslint-disable */

import { Components, Helpers } from "manageplaces-ui-kit";
import { errorMessage as gqlErrorMessage } from "@/helpers/GraphQLHelpers";

import ChangeOrders from "@/graphql/queries/finance/ChangeOrders.gql";
import ProjectsQuery from "@/graphql/queries/core/projects/Projects.gql";
import ChangeOrderManager from "./ChangeOrderManager";
import ChangeOrderStatusCellRenderer from "./ChangeOrderStatusCellRenderer.vue";

import { orderMenuItems, bulkActions, ACTIONS } from "./ContextMenuItems";

export default {
  extends: Components.BaseTable,
  apollo: {
    orders: {
      query: ChangeOrders,
      variables() {
        let vars = {};

        if (this.searchTerm) {
          vars.search = this.searchTerm;
        }

        vars.where = this.filters;
        if (this.projectId) {
          vars.where.project = this.projectId;
        }

        return vars;
      },
      update({ changeOrders }) {
        return changeOrders;
      },
      result() {
        this.setRowData(this.orders);
        this.stopLoading();
      },
      fetchPolicy: "no-cache"
    },
    projects: {
      query: ProjectsQuery,

      update(data) {
        return data.projects.edges.map(({ node }) => {
          return { label: node.name, value: node.id };
        });
      },

      fetchPolicy: "no-cache"
    }
  },

  props: {
    projectId: {
      type: [String, Number],
      required: false
    }
  },

  data() {
    const vm = this;

    return {
      filters: {},
      orderManager: new ChangeOrderManager(),
      columns: [
        {
          headerName: "#",
          field: "number",
          cellRenderer: "link",
          cellRendererParams: {
            onClick: order =>
              (window.location.href = `/projects/${order.project.id}/change_orders/${order.id}`)
          }
        },
        {
          headerName: "Name",
          field: "name",
          cellRenderer: "name",
          canToggle: false
        },
        {
          headerName: "Deadline",
          field: "approvalDeadline",
          width: 150,
          cellRenderer: "date",
          cellRendererParams: {
            highlightOverdue: true
          },
          cellEditor: "dateCellEditor"
        },
        {
          headerName: "Project",
          field: "project",
          cellRenderer: "link",
          cellRendererParams: {
            onClick: order =>
              (window.location.href = `/projects/${order.project.id}`)
          },
          comparator(a, b) {
            return a.toLowerCase().localeCompare(b.toLowerCase());
          },
          valueGetter({ data }) {
            if (!data.project) {
              return "";
            }

            return `${data.project.sequence} - ${data.project.name}`;
          }
        },
        {
          headerName: "Purchase amount",
          field: "costTotal",
          valueGetter({ data }) {
            return vm.formatMoney(data.costTotal, data.currency);
          }
        },
        {
          headerName: "Sale amount",
          field: "saleTotal",
          valueGetter({ data }) {
            return vm.formatMoney(data.saleTotal, data.currency);
          }
        },
        {
          headerName: "Status",
          field: "status",
          cellRenderer: "status",
          valueGetter(params) {
            return params.data.status;
          }
        },
        Helpers.table.actionsCell()
      ],
      config: {
        actionBar: false,
        resourceType: "Change order",
        filters: {
          name: {
            type: "text",
            title: "Name",
            description: "Filter orders by name",
            field: "name"
          },
          deadline: {
            type: "date",
            title: "Target deadline",
            description: "Filter orders by deadline",
            field: "approvalDeadline"
          },
          status: {
            type: "option",
            title: "Status",
            description: "Filter orders by status",
            field: "status",
            options: () => [
              {
                label: "Draft",
                value: "draft"
              },
              {
                label: "Submitted",
                value: "submitted"
              },
              {
                label: "Authorised",
                value: "authorised"
              },
              {
                label: "Rejected",
                value: "rejected"
              }
            ]
          },
          project: {
            type: "option",
            title: "Projects",
            description: "Filter orders by project",
            field: "project",
            hidden: this.projectId,
            options: () => this.projects
          }
        }
      },
      components: {
        vue: {
          status: ChangeOrderStatusCellRenderer
        }
      },
      listeners: {},
      options: {}
    };
  },

  computed: {},

  mounted() {
    this.startLoading();
  },

  methods: {
    performFiltering(filters) {
      this.startLoading();
      this.filters = filters;
      this.$apollo.queries.orders.refetch();
    },
    formatMoney(money, currency) {
      return accounting.formatMoney(money, this.moneyOpts(currency));
    },
    moneyOpts(currency) {
      if (currency === null) {
        return {};
      }

      return {
        symbol: currency.symbol,
        decimal: currency.decimal_mark,
        thousand: currency.thousands_separator,
        precision: currency.decimal_places
      };
    },
    getButtons(h) {
      const buttons = [
        h(
          Components.AppButton,
          { props: { primary: true }, on: { click: this.newOrder } },
          "New change order"
        )
      ];

      return buttons;
    },
    newOrder() {
      if (this.projectId) {
        window.location.href = `/projects/${this.projectId}/change_orders/new`;
      } else {
        window.location.href = "/change_orders/new";
      }
    },
    getBulkActions() {
      return bulkActions;
    },
    getContextMenuItems() {
      return orderMenuItems();
    },
    performBulkAction(item) {
      switch (item.action) {
        case ACTIONS.DELETE:
          this.delete(this.gridApi().getSelectedNodes(), {
            title: "Are you sure you want to delete these change orders?",
            message: "Deleting a change order cannot be undone",
            success: "Items successfully deleted"
          });
          break;
      }
    },
    contextMenuItemClicked(item, row) {
      switch (item.action) {
        case ACTIONS.EDIT:
          window.location.href = `/change_orders/${row.data.id}/edit`;
          break;

        case ACTIONS.DELETE:
          this.delete(row, {
            title: "Are you sure you want to delete this change order?",
            message: "Deleting a change order cannot be undone",
            success: "Change order successfully deleted"
          });
          break;
      }
    },
    delete(rows, opts) {
      if (rows.constructor !== Array) {
        rows = [rows];
      }

      const orders = rows.map(row => row.data);
      this.$dialog
        .confirm({
          title: opts.title,
          message: opts.message
        })
        .onOk(({ api }) => {
          api.hide();
          this.orderManager
            .delete(orders)
            .then(() => {
              this.$flash.success(opts.success);
              this.removeRows(rows);
            })
            .catch(e => {
              this.$flash.error(gqlErrorMessage(e));
            });
        });
    },
    removeRows(rows) {
      this.gridApi().applyTransaction({
        remove: rows
      });
    }
  }
};
</script>
