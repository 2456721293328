import { pluralize } from "@/helpers/StringHelpers";

export function confirmDelete(tasks, onOkCb) {
  tasks = Array.isArray(tasks) ? tasks : [tasks];

  const opts = {
    title: `Confirm deletion of ${pluralize("task", tasks.length)}?`,
    message: "Deleting a task cannot be undone",
    success: `${pluralize("Task", tasks.length)} successfully deleted`
  };

  this.$dialog
    .confirm({
      title: opts.title,
      message: opts.message,
      danger: true
    })
    .onOk(({ api }) => {
      api.hide();
      if (typeof onOkCb === "function") onOkCb(opts.success);
    });
}
