<template lang="pug">
  .flex.w-full.justify-between.items-stretch
    .flex-1.my-3.flex.flex-col(v-if="editing" :class="{ 'bg-white border-grey-30': isNew }")
      form-wrapper(:validator="$v.resourceField")
        template(v-if="isNew")
          app-header(size="h4") Add a custom field

          .mb-2 Custom field
          app-dropdown-field.flex-1(name="customField.id"  v-model="resourceField.customField.id" :show-optional="false" @input="clearValue(resourceField)"
                                    :options="notUsedCustomFields" track-by="id" value-attr="id" label-attr="name" :messages="messages.customField")

        .block(v-else)
          .w-full.font-bold.mb-2 {{ resourceField.customField.name }}

        template(v-if="resourceField.customField.id")
          component.w-full(:is="customFieldComponent" v-bind="componentProps" @input="formatValue($event)"
                           :value="valueForInput(resourceField)" :messages="messages.valueForInput")

        .buttons
          app-button.mr-2(primary slim @click="saveResourceField(resourceField)") {{ isNew ? "Add custom field" : "Save" }}
          app-button(default slim @click="stopEditing") Cancel

    .flex-1.my-3(v-else)
      .flex
        .block.flex-1
          .w-full.font-bold.mb-2 {{ resourceField.customField.name }}
        .font-bold(v-if="resource.canEdit")
          app-link.mr-2(@click="startEditing") Edit
          app-link(danger @click="removeResourceField") Delete

      .w-full(v-if="isTextFieldType" v-html="resourceFieldValue(resourceField)")
      .w-full(v-else) {{ resourceFieldValue(resourceField) }}
</template>

<script>
/* global accounting */

import clone from "ramda/src/clone";
import toLower from "ramda/src/toLower";
import { parse } from "date-fns";
import { required } from "vuelidate/lib/validators";
import { formatDateApi, formatDate } from "@/helpers/DateHelpers";
import CreateResourceField from "@/graphql/mutations/resource_fields/CreateResourceField.gql";
import UpdateResourceField from "@/graphql/mutations/resource_fields/UpdateResourceField.gql";
import DeleteResourceField from "@/graphql/mutations/resource_fields/DeleteResourceField.gql";

const fieldTypeComponentProps = {};

export default {
  props: {
    value: {
      type: Object,
      required: true
    },

    resource: {
      type: Object,
      required: true
    },

    customFields: {
      type: Array,
      default: () => []
    },

    notUsedCustomFields: {
      type: Array,
      default: () => []
    },

    isNew: {
      type: Boolean,
      required: false
    }
  },

  data() {
    return {
      resourceField: { ...this.value },
      resourceFieldBeforeEdit: null,
      editing: this.isNew,
      messages: {
        customField: { required: "Please select a custom field" },
        valueForInput: { required: "Value is required" }
      }
    };
  },

  validations: {
    resourceField: {
      valueForInput: { required },
      customField: {
        id: { required }
      }
    }
  },

  computed: {
    customFieldComponent() {
      const { customField } = this.resourceField;
      const fieldType = this.fieldType(customField.id);

      if (this.isList(customField.id)) {
        return "AppDropdownField";
      } else if (fieldType == "DateField" || fieldType == "date") {
        return "AppDateField";
      } else if (fieldType == "currency") {
        return "AppNumberField";
      } else {
        return "AppTextField";
      }
    },

    componentProps() {
      const { resourceField } = this;
      const { customField } = resourceField;

      if (fieldTypeComponentProps[customField.id])
        return fieldTypeComponentProps[customField.id];

      const fieldType = this.fieldType(customField.id);

      const props = {
        name: "valueForInput",
        type: fieldType,
        "show-optional": false,
        showCaption: false
      };

      if (this.isList(customField.id)) {
        props["value-attr"] = "id";
        props["track-by"] = "id";
        props["label-attr"] = "value";
        props["options"] = this.customFieldListItems[customField.id];
        props["multiple"] = fieldType == "predefined_list_multi";
      }

      if (fieldType == "text") {
        props["type"] = "richtext";
      }

      if (fieldType == "currency") {
        props["type"] = "currency";
        props["currency"] = this.$store.state.defaultCurrency;
      }

      fieldTypeComponentProps[customField.id] = props;

      return props;
    },

    isTextFieldType() {
      const { resourceField } = this;
      const { customField } = resourceField;
      const fieldType = this.fieldType(customField.id);

      return fieldType === "text";
    },

    fieldType() {
      const fieldTypes = {};
      this.customFields.forEach(cf => (fieldTypes[cf.id] = cf.type));

      return fieldId => fieldTypes[fieldId] || "string";
    },

    customFieldListItems() {
      const fieldItems = {};

      this.customFields.forEach(cf => {
        if (this.isList(cf.id)) {
          fieldItems[cf.id] = cf.customFieldListItems;
        }
      });

      return fieldItems;
    },

    moneyOpts() {
      const currency = this.$store.state.defaultCurrency;
      return {
        symbol: currency.symbol,
        decimal: currency.decimalMark,
        thousand: currency.thousandsSeparator,
        precision: currency.decimalPlaces
      };
    }
  },

  watch: {
    "resourceField.customField.id": {
      handler(newId) {
        this.setDefaultValue(newId);
      }
    }
  },

  methods: {
    startEditing() {
      this.editing = true;
      this.resourceFieldBeforeEdit = clone(this.resourceField);
    },

    stopEditing() {
      this.editing = false;
      if (this.isNew) {
        this.$emit("cancel");
      } else {
        this.resourceField = this.resourceFieldBeforeEdit;
      }
    },

    setDefaultValue(customFieldId) {
      if (toLower(this.fieldType(customFieldId)).startsWith("date")) {
        this.resourceField.valueForInput = new Date();
      } else {
        this.resourceField.valueForInput = null;
      }
    },

    clearValue() {
      const { resourceField } = this;

      if (
        this.fieldType(resourceField.customField.id) == "predefined_list_multi"
      ) {
        resourceField.valueForInput = [];
      } else {
        resourceField.valueForInput = null;
      }
    },

    removeResourceField() {
      this.$dialog
        .confirm({
          title: "Delete custom field",
          message: "Are you sure you want to delete this custom field?"
        })
        .onOk(({ api }) => {
          api.hide();

          const { id } = this.resourceField;

          this.$apollo
            .mutate({
              mutation: DeleteResourceField,
              variables: {
                input: { id }
              }
            })
            .then(() => {
              this.$emit("remove", id);
              this.$flash.success("Field removed successfully");
            });
        });
    },

    resourceFieldValue(resourceField) {
      let { customField, valueForInput } = resourceField;
      const fieldType = this.fieldType(customField.id);

      if (valueForInput && this.isList(customField.id)) {
        const fieldListItems = this.customFieldListItems[customField.id];
        const selectedValues = valueForInput.split(",");

        let fieldItems = fieldListItems.filter(f =>
          selectedValues.includes(f.id)
        );

        return fieldItems.map(fi => fi.value).join(", ");
      } else if (fieldType === "date") {
        return formatDate(valueForInput, this.$store.state.dateFormats.dateFns);
      } else if (fieldType === "currency") {
        return accounting.formatMoney(valueForInput, this.moneyOpts);
      } else {
        return valueForInput;
      }
    },

    valueForInput(resourceField) {
      let { customField, valueForInput } = resourceField;
      const fieldType = this.fieldType(customField.id);

      if (valueForInput && fieldType === "predefined_list_multi") {
        return valueForInput.split(",");
      }

      return valueForInput;
    },

    isList(customFieldId) {
      const fieldType = this.fieldType(customFieldId);

      return ["list", "predefined_list", "predefined_list_multi"].includes(
        fieldType
      );
    },

    formatValue(value) {
      const { resourceField } = this;

      if (value) {
        const type = this.fieldType(resourceField.customField.id);

        if (type === "date" || type === "DateField") {
          value = formatDateApi(parse(value, "yyyy/MM/dd", new Date()));
        } else if (type === "predefined_list_multi") {
          value = value.join(",");
        } else if (type === "currency") {
          // The server is expecting a string so we'll conver it
          value = value.toString();
        }
      }

      resourceField.valueForInput = value;
    },

    saveResourceField() {
      this.$v.$touch();

      if (this.$v.$invalid) return;

      const { resource, resourceField, isNew, customFields } = this;
      const input = {
        customFieldId: resourceField.customField.id,
        valueForInput: resourceField.valueForInput
      };
      let mutation, message;

      if (isNew) {
        mutation = CreateResourceField;
        input.resourceType = "task";
        input.resourceId = resource.id;
        message = "Custom field created successfully";
        // errorMessage = "Custom field creation failed";
      } else {
        mutation = UpdateResourceField;
        input.id = resourceField.id;
        message = "Custom field updated successfully";
        // errorMessage = "Custom field update failed";
      }

      this.$apollo
        .mutate({
          mutation,
          variables: {
            input
          }
        })
        .then(({ data }) => {
          const mutation = isNew
            ? "createResourceField"
            : "updateResourceField";
          this.$emit("saved", data[mutation].resourceField);
          this.editing = false;
          this.$flash.success(message);
        })
        .catch(error => {
          if (
            customFields.find(
              v => v.id === input.customFieldId && v.type === "currency"
            )
          ) {
            this.$flash.error(
              "Please enter a valid currency number, for example 100.00, without your currency symbol"
            );
          } else {
            this.$flash.error(error);
          }
        });
    }
  }
};
</script>
