import { apolloClient } from "@/vue-apollo";
import gql from "graphql-tag";

export function errorMessage(err) {
  if (!err) {
    return "An unexpected error occurred";
  }

  return err.message.replace("GraphQL error: ", "");
}

/**
 * A helper function for running apollo mutations
 * outside of a component.
 **/
export function runMutation(
  mutation,
  variables,
  additionalInput = {},
  mutationOpts = {}
) {
  const opts = {
    mutation,
    variables: {
      input: {
        ...variables
      },
      ...additionalInput
    },
    ...mutationOpts
  };

  return apolloClient.mutate(opts);
}

/**
 * A helper function for running apollo queries
 * outside of a component
 **/
export function runQuery(query, variables) {
  return apolloClient.query({
    query,
    variables
  });
}

/**
 * Dynamically generates a query to fetch
 * enabled features
 */
export function featureQuery(features) {
  const fields = features.join("\n");
  return gql`query company {
      company {
        features {
          ${fields}
        }
      }
    }
  `;
}
