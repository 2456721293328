<template lang="pug">
  .h-full.flex.flex-col.relative
    .comments.flex-1.overflow-auto.mb-3(ref="comments")
      .h-full.flex.flex-col.justify-center.p-8(v-if="!hasComments")
        app-header.text-center
          | No comments
          template(#subheader) No one has commented on this {{ resourceType.toLowerCase() }}

      transition-group.flex.flex-col.p-8(v-else, name="comments", tag="div", @enter="commentAdded")
        comment.comment(v-for="comment in comments", :key="comment.id", :comment="comment")

    .flex-initial.input.flex.flex-col.border.border-grey-60.rounded.overflow-hidden.mx-8.mb-8
      textarea.p-2.flex-1.w-full.h-16.resize-none(ref="commentInput" type="text", name="test", @keydown.enter="enterPressed",
                                                  @keydown="keydown", @keyup="keyup", v-model="newComment")
      app-dropdown(v-if="filteredUsers.length" :origin="dropdownOrigin" trigger='manual' @close="closeDropdown")
        template(#content)
          .w-64.overflow-y-auto(ref="usersDropdown" style="maxHeight: 250px;")
            .p-4.cursor-pointer(tabindex='1' v-for="(user, idx) in filteredUsers" :key="user.id" :ref="`user-${idx}`" @click="userSelected(user)"
                                class="focus:bg-grey-40 focus:outline-none hover:bg-grey-20" @keyup.enter="userSelected(user)" @keydown="focusNextUser($event, idx)")
              app-team-member(:user="user")

      .flex-initial.flex.items-center.justify-end.p-2.bg-grey-10.border-t.border-grey-30
        app-icon.flex-initial.mr-3.cursor-pointer(ref="dropdownTriggerIcon" icon="email" @click.native="startMention")
        app-button.flex-initial(icon="send", primary, slim, @click="addComment", :loading="loading") Send

</template>

<script>
import { errorMessage as gqlErrorMessage } from "@/helpers/GraphQLHelpers";
import CreateComment from "@/graphql/mutations/comments/CreateComment.gql";
import CommentsQuery from "@/graphql/queries/core/comments/Comments.gql";
import ResourceUsers from "@/graphql/queries/core/resource/Users.gql";
import Comment from "./Comment.vue";

export default {
  name: "CommentsPanel",
  components: {
    Comment
  },

  props: {
    comments: {
      type: Array,
      default: () => []
    },

    resourceId: {
      type: String,
      required: false,
      default: null
    },

    resourceType: {
      type: String,
      required: false,
      default: ""
    }
  },

  data() {
    return {
      loading: false,
      newComment: "",
      users: [],
      mentionsPositions: [],
      usersFilter: "",
      dropdownOrigin: null,
      commentInput: null
    };
  },

  apollo: {
    comments: {
      query: CommentsQuery,

      variables() {
        return {
          resourceId: this.resourceId,
          resourceType: this.resourceType
        };
      },

      update(data) {
        return data.comments.edges.map(({ node }) => node);
      }
    },

    users: {
      query: ResourceUsers,

      variables() {
        return {
          resourceId: this.resourceId,
          resourceType: this.resourceType
        };
      },

      update(data) {
        return data.resourceUsers.edges.map(edge => edge.node);
      }
    }
  },

  computed: {
    hasComments() {
      return this.comments && this.comments.length > 0;
    },

    filteredUsers() {
      if (this.usersFilter)
        return this.users.filter(u =>
          u.name.toLowerCase().startsWith(this.usersFilter.toLowerCase())
        );
      return this.users;
    },

    isDropdownOpen() {
      return !!this.dropdownOrigin;
    }
  },

  watch: {
    newComment(newComment, oldComment) {
      if (this.newCommentChangeCause === "userSelected") {
        this.newCommentChangeCause = null;
        return;
      }

      const charsCount = newComment.length - oldComment.length;
      const manyCharsAddOrDelete = Math.abs(charsCount) > 1;
      const currentPosition = this.commentInput.selectionStart;

      if (manyCharsAddOrDelete) {
        this.mentionsPositions = [];
        this.findAllMentionsPositions();
      } else {
        this.updateMentionsPositions(currentPosition, charsCount);
      }
      let mentionPosition = this.firstMentionPositionBefore(currentPosition);

      if (Number.isInteger(mentionPosition)) {
        this.usersFilter = newComment.slice(
          mentionPosition + 1,
          this.commentInput.selectionStart
        );
        this.isDropdownOpen || this.openDropdown();
      } else {
        this.usersFilter = "";
        this.closeDropdown();
      }
    }
  },

  mounted() {
    this.scrollToLastComment();
    this.commentInput = this.$refs.commentInput;
  },

  methods: {
    addComment() {
      const body = this.newComment.trim();

      if (body !== "") {
        this.loading = true;

        // Only submit if we actually have a value
        this.$apollo
          .mutate({
            mutation: CreateComment,
            variables: {
              input: {
                resource: {
                  resourceType: this.resourceType,
                  id: this.resourceId
                },

                body: body
              }
            }
          })
          .then(({ data: { createComment } }) => {
            this.newComment = "";
            this.$emit("comment", createComment.comment);
            this.loading = false;
          })
          .catch(e => {
            this.$flash.error(gqlErrorMessage(e));
            this.loading = false;
          });
      }
    },

    commentAdded() {
      this.scrollToLastComment();
    },

    scrollToLastComment() {
      this.$nextTick(() => {
        this.$refs.comments.scrollTop = this.$refs.comments.scrollHeight;
      });
    },

    openDropdown() {
      const commentInputRect = this.commentInput.getBoundingClientRect();
      this.dropdownOrigin = {
        getBoundingClientRect: () => commentInputRect
      };
      this.focusCommentInput();
    },

    closeDropdown() {
      this.dropdownOrigin = null;
    },

    focusCommentInput(cursorPosition) {
      this.$nextTick(() => {
        this.commentInput.focus();
        if (cursorPosition) {
          this.commentInput.selectionStart = cursorPosition;
          this.commentInput.selectionEnd = cursorPosition;
        }
      });
    },

    enterPressed(e) {
      if (e.ctrlKey || e.metaKey) {
        this.addComment();
      }
    },

    keydown(e) {
      const { selectionStart } = this.commentInput;
      const charBefore = this.newComment[selectionStart - 1];
      const isMention = e.key === "@" && (!charBefore || /\s/.test(charBefore));

      if (
        this.isDropdownOpen &&
        (e.key === "ArrowUp" || e.key === "ArrowDown")
      ) {
        e.preventDefault();
      }

      if (isMention) {
        this.recordMentionPosition(selectionStart);
      }
    },

    keyup(e) {
      const { selectionStart } = this.commentInput;
      let mentionPositionBefore = this.firstMentionPositionBefore(
        selectionStart
      );

      switch (e.key) {
        case "@":
          if (/\s/.test(this.newComment[selectionStart - 1])) {
            this.recordMentionPosition(selectionStart);
          }
          break;

        case "ArrowUp":
          if (this.isDropdownOpen) {
            this.focusNextUser(e, this.filteredUsers.length);
          }
          break;

        case "ArrowDown":
          if (this.isDropdownOpen) {
            this.focusNextUser(e, -1);
          }
          break;
        case "ArrowLeft":
        case "ArrowRight":
          if (Number.isInteger(mentionPositionBefore)) {
            const charBefore = this.newComment[selectionStart - 1];

            this.usersFilter = this.newComment.slice(
              mentionPositionBefore + 1,
              selectionStart
            );

            if (
              charBefore === "@" ||
              (this.usersFilter && this.filteredUsers.length)
            ) {
              this.openDropdown();
            } else {
              this.closeDropdown();
            }
          }
          break;
        default:
          break;
      }
    },

    focusNextUser(e, userIdx) {
      let nextUserIdx = userIdx;

      if (e.key === "ArrowUp") {
        if (nextUserIdx === 0) {
          nextUserIdx = this.filteredUsers.length - 1;
        } else if (nextUserIdx) {
          nextUserIdx--;
        }
      } else if (e.key === "ArrowDown") {
        nextUserIdx++;
      }

      nextUserIdx = nextUserIdx % this.filteredUsers.length;
      const userOption = this.$refs["user-" + nextUserIdx];
      userOption && userOption.length && userOption[0].focus();
    },

    userSelected(user) {
      const currentPosition = this.commentInput.selectionStart;
      const mentionPosition = this.firstMentionPositionBefore(currentPosition);
      let mentionEnd = mentionPosition + 1;
      let lastCharInMention = this.newComment[mentionEnd];
      let mentionText = "";

      const mentionCommentSlice = this.newComment.slice(mentionPosition + 1);

      const oldMentionedUser = this.users.find(u =>
        mentionCommentSlice.startsWith(u.name)
      );

      if (oldMentionedUser) {
        // + 2 is, 1 for mentionSign (@) and 1 for space at the end of the mention
        mentionEnd = mentionPosition + oldMentionedUser.name.length + 2;
      } else {
        while (lastCharInMention) {
          mentionText += lastCharInMention;
          if (!user.name.startsWith(mentionText)) {
            break;
          } else {
            lastCharInMention = this.newComment[++mentionEnd];
          }
        }
      }

      mentionText = `@${user.name} `;

      this.newComment = this.setStrInRange(
        this.newComment,
        mentionText,
        mentionPosition,
        mentionEnd
      );

      this.closeDropdown();
      this.focusCommentInput(mentionPosition + mentionText.length);

      this.newCommentChangeCause = "userSelected";
    },

    startMention() {
      const { commentInput } = this;
      let { selectionStart: mentionStart } = commentInput;
      const charBefore = this.newComment[mentionStart - 1];

      if (charBefore !== "@") {
        let mentionSign = "@";

        // if not space or undefined
        if (charBefore) {
          mentionSign = " @";
          mentionStart++;
        }

        this.recordMentionPosition(mentionStart);

        this.newComment = this.setStrInRange(
          this.newComment,
          mentionSign,
          mentionStart,
          mentionStart
        );

        commentInput.selectionEnd = mentionStart + 1;
      }
      this.openDropdown();
    },

    recordMentionPosition(position = this.commentInput.selectionStart) {
      const positionBefore = this.firstMentionPositionBefore(position);
      if (positionBefore === position) return;

      const positionBeforeIdx = this.mentionsPositions.indexOf(positionBefore);

      this.mentionsPositions.splice(positionBeforeIdx + 1, 0, position);
    },

    updateMentionsPositions(currentPosition, by) {
      for (let idx = this.mentionsPositions.length - 1; idx >= 0; idx--) {
        const position = this.mentionsPositions[idx];

        if (position > currentPosition) {
          this.mentionsPositions[idx] += by;
        } else {
          if (position === currentPosition && by < 0) {
            this.mentionsPositions.splice(idx, 1);
          }
          break;
        }
      }
    },

    deleteMentionPosition(mentionPosition) {
      const idx = this.mentionsPositions.indexOf(mentionPosition);
      this.mentionsPositions.splice(idx, 1);
    },

    firstMentionPositionBefore(position) {
      for (let idx = this.mentionsPositions.length - 1; idx >= 0; idx--) {
        let mPosition = this.mentionsPositions[idx];
        if (mPosition <= position) {
          return mPosition;
        }
      }
    },

    findAllMentionsPositions() {
      for (let position = 0; position < this.newComment.length; position++) {
        if (this.newComment[position] === "@")
          this.mentionsPositions.push(position);
      }
    },

    setStrInRange(str, substr, rangeStart, rangeEnd) {
      const textBefore = str.slice(0, rangeStart);
      const textAfter = str.slice(rangeEnd);

      return [textBefore, substr, textAfter].join("");
    }
  }
};
</script>

<style scoped>
.comments {
  bottom: 8rem;
}

.input {
  height: 8rem;
}

.comment {
  transform: translate3d(0, 0, 0);
  opacity: 1;
}

.comments-enter-active,
.comments-leave-active {
  transition: all 0.5s;
}

.comments-enter,
.comments-leave-to {
  opacity: 0;
  transform: translate3d(100%, 0, 0);
}
</style>
