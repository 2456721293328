import { apolloClient } from "@/vue-apollo";
import path from "ramda/src/path";
import DocumentURLQuery from "@/graphql/queries/document_management/DocumentURL.gql";
import { triggerDownload, triggerZipDownload } from "@/helpers/DownloadHelpers";
import { isFolder, mutationInputIDs } from "@/helpers/DocumentHelpers";

import CopyDocuments from "@/graphql/mutations/documents/copyDocuments.gql";
import MoveDocuments from "@/graphql/mutations/documents/moveDocuments.gql";
import DeleteDocument from "@/graphql/mutations/documents/deleteDocument.gql";
import DeleteFolder from "@/graphql/mutations/documents/deleteFolder.gql";
import DeleteDocumentVersion from "@/graphql/mutations/documents/deleteDocumentVersion.gql";
import RenameDocument from "@/graphql/mutations/documents/renameDocument.gql";
import RenameFolder from "@/graphql/mutations/documents/renameFolder.gql";
import CreateFolder from "@/graphql/mutations/documents/createFolder.gql";
import AttachDocumentsToTasks from "@/graphql/mutations/tasks/AttachDocumentsToTasks.gql";
import DetachDocumentFromTask from "@/graphql/mutations/documents/detachDocumentFromTask.gql";
import generateDocumentShareUrl from "@/graphql/mutations/documents/generateDocumentShareUrl.gql";
import {
  errorMessage as gqlErrorMessage,
  runMutation
} from "@/helpers/GraphQLHelpers";
import { Flash } from "manageplaces-ui-kit";

const CACHE = {
  DELETE: "delete",
  UPDATE: "update",
  CREATE: "create"
};

export default class DocumentManager {
  constructor(project, mode = "move") {
    this.project = project;
    this.mode = mode;
  }

  setCacheQuery(query, variables) {
    this.updateCache = true;
    this.cacheQuery = query;
    this.cacheQueryVariables = variables;
  }

  /**
   * Creates a new folder. Optionall, the parent folder
   * can be provided if it is nested.
   */
  createFolder(parent) {
    let vars = {};

    if (this.project) {
      vars.project = { id: this.project.id };
    }

    if (parent) {
      vars.parent = { id: parent.id };
    }

    return this._runMutation(CreateFolder, vars, {
      action: CACHE.CREATE,
      responsePath: "createFolder.folder",
      cachePath: "documentsConnection.edges"
    }).then(({ data }) => {
      return data.createFolder.folder;
    });
  }

  /**
   * Copy the specified documents and folders
   */
  move(docsAndFolders, folder, options = {}) {
    if (!docsAndFolders || !docsAndFolders.length) return Promise.resolve();

    const input = mutationInputIDs(docsAndFolders);

    input.parent = null;
    if (folder.id) input.parent = { id: folder.id };

    input.project = null;
    if (this.project) input.project = { id: this.project.id };

    const mutation = this._isCopy() ? CopyDocuments : MoveDocuments;
    return this._runMutation(mutation, { ...input, ...options }).catch(e => {
      Flash.error(gqlErrorMessage(e));
      throw e;
    });
  }

  /**
   * Delete the specified documents and folders
   */
  delete(docsAndFolders) {
    // let mutation;
    // let responsePath;
    const input = mutationInputIDs(docsAndFolders);

    if (this.project) {
      input.project = { id: this.project.id };
    }

    // if (isFolder(docsAndFolders)) {
    //   mutation = DeleteFolder;
    //   responsePath = "deleteFolder.folder";
    // } else {
    //   mutation = DeleteDocument;
    //   responsePath = "deleteDocument.document";
    // }

    let deleteDocs;
    let deleteFolders;

    if (input.documents.length) {
      deleteDocs = this._runMutation(DeleteDocument, {
        project: input.project,
        documents: input.documents
      });
    }

    if (input.folders.length) {
      deleteFolders = this._runMutation(DeleteFolder, {
        project: input.project,
        folders: input.folders
      });
    }

    return Promise.all([deleteDocs, deleteFolders]);
    // return this._runMutation(
    //   mutation,
    //   { id: docsAndFolders.id },
    //   {
    //     action: CACHE.DELETE,
    //     responsePath: responsePath,
    //     cachePath: "documentsConnection.edges"
    //   }
    // );
  }

  /**
   * Triggers the browser to download the specified
   * documents.
   */
  downloadDocuments(docsAndFolders, relativePath) {
    const input = mutationInputIDs(docsAndFolders);

    if (input.documents.length === 1 && !input.folders.length) {
      // Just a single download so we can download the file directly
      this._runQuery(DocumentURLQuery, input.documents[0]).then(({ data }) => {
        const {
          document: {
            currentVersion: { url }
          }
        } = data;

        triggerDownload(url);
      });
    } else {
      // Multiple documents, or a folder, so we need to download
      // as a zip
      triggerZipDownload(input, relativePath, { project: this.project });
    }
  }

  /**
   * Attempts to rename a document or folder
   */
  rename(docOrFolder, newName) {
    let mutation;
    let responsePath;

    if (isFolder(docOrFolder)) {
      mutation = RenameFolder;
      responsePath = "renameFolder.folder";
    } else {
      mutation = RenameDocument;
      responsePath = "renameDocument.document";
    }

    return this._runMutation(
      mutation,
      { id: docOrFolder.id, name: newName },
      {
        action: CACHE.UPDATE,
        responsePath: responsePath,
        cachePath: "documentsConnection.edges",
        updateKeys: { name: "name", path: "path" }
      }
    ).then(data => {
      const newPath = this._valueForPath(data.data, responsePath).path;
      docOrFolder.path = newPath;
      return data;
    });
  }

  generateDocumentShareUrl(docsAndFolders) {
    const input = mutationInputIDs(docsAndFolders);

    return this._runMutation(generateDocumentShareUrl, input, {
      action: CACHE.CREATE,
      responsePath: generateDocumentShareUrl.share
    });
  }

  deleteDocumentVersion(params) {
    let mutation = DeleteDocumentVersion;
    let responsePath;

    return this._runMutation(mutation, params, {
      action: CACHE.UPDATE,
      responsePath: responsePath,
      cachePath: "documentsConnection.edges",
      updateKeys: { name: "name", path: "path" }
    }).then(data => {
      return data;
    });
  }

  detachDocument(document, task) {
    return runMutation(DetachDocumentFromTask, {
      taskId: task.id,
      documentId: {
        id: document.id,
        resourceType: document.__typename == "Folder" ? "folder" : "file"
      }
    });
  }

  attachDocument(data) {
    return runMutation(AttachDocumentsToTasks, data);
  }

  /**
   * PRIVATE FUNCTIONS
   */

  /**
   * Returns a cache update function to be used by
   * apollo mutations.
   */
  _cacheUpdate(cacheOperation) {
    return (store, { data }) => {
      const cachedData = store.readQuery({
        query: this.cacheQuery,
        variables: this.cacheQueryVariables
      });

      const cachePath = cacheOperation.cachePath.split(".");
      const cachedDocs = path(cachePath, cachedData);
      const responseData = this._valueForPath(
        data,
        cacheOperation.responsePath
      );

      if (cacheOperation.action === CACHE.DELETE) {
        const idx = cachedDocs.findIndex(
          doc => doc.node.id === responseData.id
        );
        cachedDocs.splice(idx, 1);
      } else if (cacheOperation.action === CACHE.UPDATE) {
        const toUpdate = cachedDocs.find(
          doc => doc.node.id === responseData.id
        );

        // Update the values with the response
        Object.keys(cacheOperation.updateKeys).forEach(targetKey => {
          const responseKey = cacheOperation.updateKeys[targetKey];
          toUpdate[targetKey] = responseData[responseKey];
        });
      } else if (cacheOperation.action === CACHE.CREATE) {
        cachedDocs.push({
          __typename: "TreeNodeUnionEdge",
          node: responseData
        });
      }
      // Write our updated cache data
      store.writeQuery({
        query: this.cacheQuery,
        variables: this.cacheQueryVariables,
        data: cachedData
      });
    };
  }

  _isCopy() {
    return this.mode === "copy";
  }

  /**
   * Executes the given mutation. Optionally, a cache
   * operation can be provided to define how the apollo
   * cache should be updated
   */
  _runMutation(mutation, variables, cacheOperation, mutationOpts = {}) {
    const opts = {
      mutation,
      variables: {
        input: {
          ...variables
        }
      },
      ...mutationOpts
    };

    if (this.updateCache) {
      opts.update = this._cacheUpdate(cacheOperation);
    }

    return apolloClient.mutate(opts);
  }

  /**
   * Executes the given query with the given variables
   */
  _runQuery(query, variables) {
    return apolloClient.query({
      query,
      variables
    });
  }

  _valueForPath(obj, responsePath) {
    const responsePathParts = responsePath.split(".");
    return path(responsePathParts, obj);
  }
}
