<template lang="pug">
  div
    .page.loader.text-center.z-10(v-if="loading")
      .circle
        .ring
    .text-grey-60(:class="loading ? 'opacity-25' : ''")
      .pointer-events-none.flex.fixed.left-0.top-0.bottom-0(class="xs:w-0 md:w-1/2 left bg-tribal-aqua")
        div.z-0.absolute.top-0.left-0.right-0.bottom-0.left-image.w-full.h-full.opacity-50
      component(v-if="company" :is="currentComponent" @step-increase="stepIncrease" :value="internalCompany")

</template>
<script>
import CompanyQuery from "@/graphql/queries/core/company/Company.gql";
import SetupCompanyMutation from "@/graphql/mutations/companies/setupCompany.gql";
import WelcomeSetup from "@/components/welcome/WelcomeSetup.vue";
import WelcomeInviteUsers from "@/components/welcome/WelcomeInviteUsers.vue";
import WelcomeComplete from "@/components/welcome/WelcomeComplete.vue";
import AppLoader from "@/components/containers/AppLoader.vue";

export default {
  apollo: {
    company: {
      query: CompanyQuery,
      result({ data: { company } }) {
        this.internalCompany.telephone = company.telephone;
        this.internalCompany.country = company.country;
        this.internalCompany.currency = company.currency;
        this.internalCompany.timezone = company.timezone;
      }
    }
  },
  components: {
    WelcomeSetup,
    AppLoader,
    WelcomeInviteUsers
  },
  data() {
    return {
      internalCompany: {
        telephone: null,
        country: null,
        currency: null,
        timezone: null,
        users: null,
        arrangeTrainingSession: false
      },
      welcomeComponents: [WelcomeSetup, WelcomeInviteUsers, WelcomeComplete],
      currentStep: 0,
      loading: false
    };
  },
  computed: {
    currentComponent() {
      return this.welcomeComponents[this.currentStep];
    }
  },
  methods: {
    setupCompany() {
      return this.$apollo
        .mutate({
          mutation: SetupCompanyMutation,
          variables: {
            input: {
              telephone: this.internalCompany.telephone,
              country: this.internalCompany.country,
              currency: this.internalCompany.currency,
              timezone: this.internalCompany.timezone,
              users: this.internalCompany.users,
              arrangeTrainingSession: this.internalCompany
                .arrangeTrainingSession
            }
          }
        })
        .then(() => {
          this.loading = false;
          window.location.href = "/projects/new";
          this.$flash.success("Your company set up has been completed");
        });
    },
    stepIncrease(internalCompany) {
      this.companyUpdated(internalCompany);
      if (this.currentStep === this.welcomeComponents.length - 1) {
        (this.loading = true), this.setupCompany();
      } else {
        this.currentStep++;
      }
    },
    companyUpdated(internalCompany) {
      Object.keys(internalCompany).forEach(k => {
        this.internalCompany[k] = internalCompany[k];
      });
    }
    // countryChanged(evt) {
    //   company {
    //     currency: availableCountries.currency,
    //     timezone: availableCountries.timezone,
    //   }
    // }
  }
};
</script>
<style lang="postcss" scoped>
.left-image {
  background: url("~@/assets/images/signup.jpg");
  background-size: cover;
  background-repeat: none;
  background-position: center;
  opacity: 0.1;
}
</style>
