<script>
/* global accounting */
/* eslint-disable */

import { Components, Helpers } from "manageplaces-ui-kit";
import { errorMessage as gqlErrorMessage } from "@/helpers/GraphQLHelpers";

import Invoices from "@/graphql/queries/finance/Invoices.gql";
import ProjectsQuery from "@/graphql/queries/core/projects/Projects.gql";
import InvoicesManager from "./InvoicesManager";
import InvoiceStatusCellRenderer from "./InvoiceStatusCellRenderer.vue";

import { invoicesMenuItems, ACTIONS } from "./InvoiceMenuItems";

export default {
  extends: Components.BaseTable,
  apollo: {
    invoices: {
      query: Invoices,
      variables() {
        let vars = {};

        if (this.searchTerm) {
          vars.search = this.searchTerm;
        }

        vars.where = this.filters;
        if (this.projectId) {
          vars.where.project = this.projectId;
        }

        return vars;
      },
      update({ invoices }) {
        return invoices;
      },
      result() {
        this.setRowData(this.invoices);
        this.stopLoading();
      },
      fetchPolicy: "no-cache"
    },
    projects: {
      query: ProjectsQuery,

      update(data) {
        return data.projects.edges.map(({ node }) => {
          return { label: node.name, value: node.id };
        });
      },

      fetchPolicy: "no-cache"
    }
  },

  props: {
    projectId: {
      type: [String, Number],
      required: false
    }
  },

  data() {
    const vm = this;

    return {
      filters: {},
      invoicesManager: new InvoicesManager(),
      columns: [
        {
          headerName: "#",
          field: "number",
          cellRenderer: "link",
          cellRendererParams: {
            onClick: invoice =>
              (window.location.href = `/projects/${invoice.project.id}/invoices/${invoice.id}`)
          }
        },
        {
          headerName: "Ref",
          field: "reference"
        },
        {
          headerName: "Contact",
          field: "contact",
          valueGetter({ data }) {
            return data.contact.name;
          }
        },
        {
          headerName: "Date",
          field: "issuedDate",
          cellRenderer: "date",
          width: 150
        },
        {
          headerName: "Due date",
          field: "dueDate",
          cellRenderer: "date",
          width: 150,
          cellRendererParams: {
            highlightOverdue: true
          }
        },
        {
          headerName: "Project",
          field: "project",
          cellRenderer: "link",
          cellRendererParams: {
            onClick: invoice =>
              (window.location.href = `/projects/${invoice.project.id}`)
          },
          comparator(a, b) {
            return a.toLowerCase().localeCompare(b.toLowerCase());
          },
          valueGetter({ data }) {
            if (!data.project) {
              return "";
            }

            return `${data.project.sequence} - ${data.project.name}`;
          }
        },
        {
          headerName: "Purchase amount",
          field: "totalVal",
          valueGetter({ data }) {
            return vm.formatMoney(data.totalVal, data.currency);
          }
        },
        {
          headerName: "Status",
          field: "status",
          cellRenderer: "status",
          width: 150,
          valueGetter(params) {
            return params.data.status;
          }
        },
        Helpers.table.actionsCell()
      ],
      config: {
        actionBar: false,
        resourceType: "Invoice",
        filters: {
          status: {
            type: "option",
            title: "Status",
            description: "Filter invoices by status",
            field: "status",
            options: () => [
              {
                label: "Draft",
                value: "draft"
              },
              {
                label: "Submitted",
                value: "submitted"
              },
              {
                label: "Authorised",
                value: "authorised"
              },
              {
                label: "Paid",
                value: "paid"
              },
              {
                label: "Voided",
                value: "voided"
              }
            ]
          },
          name: {
            type: "text",
            title: "Number",
            description: "Filter invoices by number",
            field: "number"
          },
          reference: {
            type: "text",
            title: "Reference",
            description: "Filter invoices by reference",
            field: "reference"
          },
          date: {
            type: "date",
            title: "Date",
            description: "Filter invoices by issue date",
            field: "issueDate"
          },
          due_date: {
            type: "date",
            title: "Due date",
            description: "Filter invoices by due date",
            field: "dueDate"
          },
          project: {
            type: "option",
            title: "Projects",
            description: "Filter orders by project",
            field: "project",
            hidden: this.projectId,
            options: () => this.projects
          }
        }
      },
      components: {
        vue: {
          status: InvoiceStatusCellRenderer
        }
      }
    };
  },

  computed: {},

  mounted() {
    this.startLoading();
  },

  methods: {
    performFiltering(filters) {
      this.startLoading();
      this.filters = filters;
      this.$apollo.queries.orders.refetch();
    },
    formatMoney(money, currency) {
      return accounting.formatMoney(money, this.moneyOpts(currency));
    },
    moneyOpts(currency) {
      if (currency === null) {
        return {};
      }

      return {
        symbol: currency.symbol,
        decimal: currency.decimal_mark,
        thousand: currency.thousands_separator,
        precision: currency.decimal_places
      };
    },
    getButtons(h) {
      const buttons = [
        h(
          Components.AppButton,
          { props: { primary: true }, on: { click: this.newOrder } },
          "New invoice"
        )
      ];

      return buttons;
    },
    newOrder() {
      if (this.projectId) {
        window.location.href = `/projects/${this.projectId}/invoices/new`;
      } else {
        window.location.href = "/invoices/new";
      }
    },
    getContextMenuItems(row) {
      return invoicesMenuItems(row);
    },
    contextMenuItemClicked(item, row) {
      switch (item.action) {
        case ACTIONS.EDIT:
          window.location.href = `/invoices/${row.data.id}/edit`;
          break;

        case ACTIONS.DELETE:
          this.delete(row, {
            title: "Are you sure you want to delete this invoice?",
            message: "Deleting an invoice cannot be undone",
            success: "Invoice successfully deleted"
          });
          break;
      }
    },
    delete(rows, opts) {
      if (rows.constructor !== Array) {
        rows = [rows];
      }

      const invoices = rows.map(row => row.data);
      this.$dialog
        .confirm({
          title: opts.title,
          message: opts.message
        })
        .onOk(({ api }) => {
          api.hide();
          this.invoicesManager
            .delete(invoices)
            .then(() => {
              this.$flash.success(opts.success);
              this.removeRows(rows);
            })
            .catch(e => {
              this.$flash.error(gqlErrorMessage(e));
            });
        });
    },
    removeRows(rows) {
      this.gridApi().applyTransaction({
        remove: rows
      });
    }
  }
};
</script>
