<template lang="pug">
  .preview.flex.justify-center.h-full.w-full
    template(v-if="fileType === 'PDF' || fileType === 'TEXT'")
      iframe.w-full.h-full(frameborder="0", :src="`https://docs.google.com/gview?url=${encodedUrl}&embedded=true`", target="_blank")
    template(v-else-if="isOfficeDocument")
      iframe.w-full.h-full(:src="`https://view.officeapps.live.com/op/embed.aspx?src=${encodedUrl}`", frameborder='0')
    template(v-else-if="fileType === 'IMAGE' && fileExtension !== 'svg'")
      img.self-center.max-w-full.max-h-full(:src="url")
    template(v-else)
      .flex.flex-col.justify-center
        app-header
          | Unsupported file type
          template(#subheader) It is not currently possible to open this file in your browser

        app-button.mt-8.self-center(icon="download", primary, @click="download") Download
</template>

<script>
import DocumentURL from "@/graphql/queries/document_management/DocumentURL.gql";
import DocumentManager from "./DocumentManager";
export default {
  props: {
    document: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      url: null
    };
  },
  apollo: {
    url: {
      query: DocumentURL,
      variables() {
        return {
          id: this.document.id,
          external: true
        };
      },
      update(data) {
        return data.document.currentVersion.url;
      }
    }
  },
  computed: {
    encodedUrl() {
      return encodeURIComponent(this.url);
    },
    fileType() {
      return this.document.currentVersion.upload.fileType;
    },
    fileExtension() {
      return this.document.currentVersion.upload.fileExtension.toLowerCase();
    },
    fileContentType() {
      return this.document.currentVersion.upload.fileContentType;
    },
    isOfficeDocument() {
      const docExtensions = ["doc", "docx", "ppt", "pptx", "xls", "xlsx"];
      return docExtensions.indexOf(this.fileExtension) !== -1;
    }
  },
  watch: {
    "document.versions": {
      handler() {
        this.$apollo.queries.url.refetch();
      }
    }
  },
  methods: {
    download() {
      const mgr = new DocumentManager();
      mgr.downloadDocuments(this.document);
    }
  }
};
</script>
